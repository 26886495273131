<template>
  <div class="flex justify-start min-w-[120px] gap-8 custom-item">
    <div class="flex">
      <input
        type="radio"
        :name="itemType + 'Custom' + index"
        value="readwrite"
        :checked="selected == 'readwrite'"
        @change="$emit('val-change', $event)"
        @input="$emit('update:customAccess', $event.target.value)"
        class="mb-0"
        :id="itemType + 'ReadWrite' + index"
      />
      <label
        :for="itemType + 'ReadWrite' + index"
        class="text-xs whitespace-nowrap"
        >R+W</label
      >
    </div>
    <div class="flex">
      <input
        type="radio"
        :name="itemType + 'Custom' + index"
        value="readonly"
        @change="$emit('val-change', $event)"
        :checked="selected == 'readonly'"
        @input="$emit('update:customAccess', $event.target.value)"
        class="mb-0"
        :id="itemType + 'Read' + index"
      />
      <label :for="itemType + 'Read' + index" class="text-xs whitespace-nowrap"
        >Read</label
      >
    </div>
    <div class="flex">
      <input
        type="radio"
        :name="itemType + 'Custom' + index"
        value="delete"
        :checked="selected == 'noaccess'"
        @change="$emit('val-change', $event)"
        @input="$emit('update:customAccess', $event.target.value)"
        class="mb-0 mr-1"
        :id="itemType + 'Del' + index"
      />
      <label
        :for="itemType + 'Del' + index"
        class="text-red-600 text-xs whitespace-nowrap"
        >DEL</label
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["itemType", "index", "selected"],
  emits: ["update:itemAccess", "val-change"],
  methods: {
    revokeCustom() {
      confirm("Are you sure you want to revoke this access?");
    },
  },
};
</script>

<style scoped>
input,
label {
  cursor: pointer;
}
input {
}

@media (max-width: 480px) {
  .custom-item .flex.justify-start {
    flex-direction: column;
    align-items: flex-start;
  }
  .custom-item .flex.justify-start > div {
    margin-bottom: 0.25rem;
  }
}
</style>
