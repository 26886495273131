import pjson from "../package.json";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store/index.js";
import "v-calendar/dist/style.css";

import Checkbox from "./components/ui/Checkbox.vue";
import ButtonInner from "./components/ui/ButtonInner.vue";
import DisplayModal from "./components/ui/DisplayModal.vue";
import scrollIntoView from "smooth-scroll-into-view-if-needed";

const app = createApp(App).use(store).use(router);

app.config.globalProperties.win = window;
app.config.globalProperties.con = console;
app.config.globalProperties.doc = document;
app.config.globalProperties.$version = pjson.version;

app.component("Checkbox", Checkbox);
app.component("ButtonInner", ButtonInner);
app.component("DisplayModal", DisplayModal);

app.mount("#app");

window.scrollIntoView = scrollIntoView;
