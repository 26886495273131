<template>
  <div class="px-2">
    <div class="font-semibold">v1.9.8</div>
    <div>
      -Group Admin Page small screen support
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.9.7</div>
    <div>
      -Added visual indicators for successful or failed saves of text input.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.9.4</div>
    <div>
      -If you are a super admin of your institution, you can now manage multiple
      labs from the same login.
      <br />Contact us if you need this.
      <div class="leading-none text-xs">&nbsp;</div>
      -Moving a box with a box ID that ends in two digits(default format) will
      now automatically renumber the digits to that of the new well position.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.9.2</div>
    <div>
      -Ability for labs to self-manage puck colors, from the puck section, in
      case of mismatch.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.9.0</div>
    <div>
      -You can now add images to your grids.
      <br />10MB file size limit. File type must be: PNG, GIF, JPEG, WebP, or
      SVG <br />&nbsp;<br />
    </div>

    <div class="font-semibold">v1.8.0</div>
    <div>
      -Support for SubAngstrom's Cryo ARM Cartridge Pucks.
      <div class="leading-none text-xs">&nbsp;</div>
      -Contact us if you need these pucks added to your account.

      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.7.2</div>
    <div>
      -Box name tags (on puck overview) are now showing on Safari.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.7.1</div>
    <div>
      -You can now auto navigate to grids from the grid explorer(and search
      bar).

      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.7.0</div>
    <div>
      -Added more details to new user activity logs.

      <div class="leading-none text-xs">&nbsp;</div>
      -<span class="font-semibold"
        >Starting June 1st, activity logs older than 3 months will be
        purged.</span
      ><br />
      If you need us to keep your activity logs for longer than 3 months please
      contact us for a custom solution.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.6.7</div>
    <div>
      -Any lab member can now use project filtering.
      <div class="leading-none text-xs">&nbsp;</div>
      -Small improvement to moving boxes UX.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.6.6</div>
    <div>
      -Lab admins can now add racks or pucks to projects.

      <br />&nbsp;<br />
    </div>

    <div class="font-semibold">v1.6.5</div>
    <div>
      -From the "Data Settings" page, you can now create a key to use the
      developer API.<br />
      Currently supports requests to get your lab's data in JSON format.

      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.6.4</div>
    <div>
      -Fixed bug with creating a new box.
      <div class="leading-none text-xs">&nbsp;</div>
      -Lab members' online status now shown on team members and group admin
      page.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.6.3</div>
    <div>
      -Lab admins can now add custom access users to projects.<br />It may be
      more convenient to add boxes to projects(added from the box itself) and
      custom access users to projects(from the project manager page), than it
      would be to add custom access users directly to boxes.<br />The latter is
      still functional.<br />If you assign a user to a project that contains a
      box which they already have direct custom access to, the users's access
      level(read+write or read only) for that box takes priority over the user's
      project access level.<br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.6.2</div>
    <div>
      -Custom permissions and read only bug fixes
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.6.1</div>
    <div>
      -Puck summary bug fix
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.6.0</div>
    <div>
      -Data entry is now saved automatically.<br />Note: Creating a box for the
      first time, and changing a box's preferences still require a manual save.
      <div class="leading-none text-xs">&nbsp;</div>
      -Support for honey colored pucks. Contact us if you have a puck(s) with an
      incorrect color.
      <div class="leading-none text-xs">&nbsp;</div>
      -Small UI bug fixes
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.5.3</div>
    <div>
      -More UI changes to support small(phone) screens. ETA 1H January
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.5.2</div>
    <div>
      -Ongoing UI changes to support small(phone) screens. ETA 1H January
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.5.1</div>
    <div>
      -Streamlined "Manage Group Members" page.
      <br />&nbsp;<br />
    </div>
    <div class="font-semibold">v1.5.0</div>
    <div>
      -From the active puck graphic, you can now move a box to an unoccupied
      well by drag & drop.
      <br />&nbsp;<br />
      More frequent updates to come in 2023. Happy Holidays! 🎄
    </div>
  </div>
</template>
