<template>
  <div
    class="flex justify-between text-sky-900 text-2xl font-bold leading-none mt-1"
  >
    <div>
      {{ $store.state.activeUser.labId.institute.name }} >
      {{
        $store.state.activeUser.activeLabId?.name ||
        $store.state.activeUser.labId.name
      }}
    </div>
    <div>
      Lab code:
      <span class="bg-yellow-500 px-2">{{
        $store.state.activeUser.activeLabId?.code ||
        $store.state.activeUser.labId.code
      }}</span>
    </div>
  </div>
  <div v-if="tempUsers.length > 0">
    <header-bar>Waiting Approval</header-bar>
    <div class="bggray mx-4 border-gray-800 border p-4 mt-4">
      <template v-for="(user, index) in tempUsers" :key="user._id">
        <div class="flex">
          <div>
            <div class="text-2xl font-bold">{{ user.name }}</div>
            <div class="text-sm mb-4">Email: {{ user.email }}</div>
          </div>
        </div>

        <div class="flex">
          <div>
            <div class="bg-sky-900 text-white font-bold px-3 py-1">
              User Role
            </div>
            <select
              class="flex flex-0 mb-0"
              @change="
                (ev) => {
                  userRoleTemp[index] = ev.currentTarget.value;
                }
              "
            >
              <option value="lab-admin">Lab Admin</option>
              <option value="user">Lab Member</option>
            </select>
            <div class="relative" v-if="userRoleTemp[index] == 'user'">
              <div
                class="border-2 border-t-0"
                style="border-color: rgb(118, 155, 186)"
              >
                <access-box
                  v-model:item-access="dewarAccessTemp[index]"
                  :selected="dewarAccessTemp[index] ?? 'readwrite'"
                  id="temp1"
                  >All Lab Access</access-box
                >
                <!-- <access-box
              v-model:item-access="rackAccessTemp[index]"
              :selected="rackAccessTemp[index] ?? 'readwrite'"
              id="temp2"
              >All Racks Access</access-box
            >
            <access-box
              v-model:item-access="puckAccessTemp[index]"
              :selected="puckAccessTemp[index] ?? 'readwrite'"
              id="temp3"
              >All Pucks Access</access-box
            >
            <access-box
              v-model:item-access="boxAccessTemp[index]"
              :selected="boxAccessTemp[index] ?? 'readwrite'"
              id="temp4"
              >All Boxes Access</access-box
            > -->
              </div>
            </div>
          </div>
          <div class="flex ml-8 items-center">
            <button
              class="bg-emerald-600 text-white rounded-md px-2 py-1 mr-8"
              @click="approveUser(user._id, user.labId._id, index)"
            >
              Approve User
            </button>
            <button
              class="bg-red-600 text-white rounded-md px-2 py-1"
              @click="deleteUser(user._id)"
            >
              Delete User
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
  <header-bar>Manage Group Members</header-bar>

  <div class="flex mx-4 mt-4 flex-col md:flex-row">
    <div
      class="grid grid-cols-1 md:w-72 xsm:grid-cols-2 gap-x-2 md:flex md:flex-col"
    >
      <div
        v-for="(user, index) in nonTempUsers"
        :key="user._id"
        class="bg-sky-900 p-2 border-blue-800 border mb-2"
      >
        <div
          @click="loadUser(user._id, index)"
          :ref="'userContainer' + index"
          class="bg-white p-2 border-blue-800 border grid grid-cols-3 cursor-pointer"
        >
          <div>
            <div class="relative mr-2 border border-black">
              <div
                style="
                  width: 100%;
                  padding-top: 100%;

                  background-size: cover;
                "
                :style="{
                  backgroundImage: `url(
                    ${
                      user.photo
                        ? $store.state.hostname + '/img/users/' + user.photo
                        : 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1583584638543x692525241529171000%2Fperson-placeholder-male-5.jpg?w=192&h=202&auto=compress&dpr=1.25&fit=max'
                    }
                  )`,
                }"
              ></div>
            </div>
          </div>
          <div
            class="flex flex-col text-sm font-bold justify-between max-w-xs"
            style="width: max-content; min-width: 7rem"
          >
            <div class="text-xs font-normal">
              <div
                :class="[
                  $store.state.activeUser._id == user._id ||
                  $store.state.usersOnline.has(user._id)
                    ? 'bg-green-500 '
                    : 'bg-gray-500',
                ]"
                class="rounded-full w-3 h-3 onlinecircle"
              ></div>
            </div>
            <div class="capitalize">
              {{ user.name }}<br />
              {{ user.isLabSuper ? "Primary " : ""
              }}{{
                user.role == "user"
                  ? "Lab Member"
                  : user.role.split("-").join(" ")
              }}
            </div>
          </div>
          <div class="flex justify-end">
            <div>
              <i
                class="fas fa-envelope text-xl text-gray-500 cursor-pointer hover:text-green-800"
                data-v-4f22f8d6=""
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:ml-4 mt-4 sm:mt-0 flex-grow">
      <div class="bggray border-black border relative">
        <div
          v-show="!selectedUser"
          class="text-center font-bold text-3xl text-red-800 w-full h-full"
        >
          Please select a user from the left panel.
        </div>
        <!-- <div :class="{ invisible: !selectedUser }"> -->
        <div v-show="selectedUser">
          <div class="grid grid-cols-2 p-2">
            <div class="flex-col flex justify-between">
              <div class="flex px-2">
                <div class="relative mr-2 border border-black">
                  <div
                    style="
                      width: 6rem;
                      padding-top: 100%;
                      position: relative;
                      background-size: cover;
                    "
                    :style="{
                      backgroundImage: `url(
                    ${
                      selectedUser?.photo
                        ? $store.state.hostname +
                          '/img/users/' +
                          selectedUser.photo
                        : 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1583584638543x692525241529171000%2Fperson-placeholder-male-5.jpg?w=192&h=202&auto=compress&dpr=1.25&fit=max'
                    }
                  )`,
                    }"
                  ></div>
                </div>
                <div class="text-3xl flex flex-col">
                  <span class="capitalize">{{ selectedUser?.name }}</span
                  ><span class="text-sm">{{ selectedUser?.email }}</span>
                </div>
              </div>
              <div
                class="flex justify-between mt-4 items-end"
                v-if="!selectedUser?.isLabSuper"
              >
                <div class="flex">
                  <input
                    type="checkbox"
                    v-model="editUser"
                    id="editUser"
                    class="w-4 h-4 cursor-pointer mb-0 mr-1"
                  />
                  <label for="editUser" class="leading-tight cursor-pointer"
                    >Edit User</label
                  >
                </div>
                <button
                  v-if="editUser"
                  @click="saveUser(selectedUser._id, selectedUser.labId._id)"
                  class="mr-4 rounded bg-green-700 px-4 text-white"
                  ref="saveUserChanges"
                >
                  Save
                </button>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex items-end">
                <select
                  v-if="
                    selectedUser?.role != 'lab-admin' ||
                    $store.state.activeUser.isLabSuper
                  "
                  :disabled="!editUser"
                  name=""
                  id=""
                  v-model="selectedUserRole"
                  @change="changeRoleMethod($event)"
                >
                  <option value="user">Member</option>
                  <option value="lab-admin">
                    {{ selectedUser?.isLabSuper ? "Primary " : "" }}Admin
                  </option>
                </select>
                <input v-else type="text" readonly value="Lab Admin" />
                <input
                  :disabled="
                    !editUser ||
                    (selectedUser.role == 'lab-admin' &&
                      !$store.state.activeUser.isLabSuper)
                  "
                  type="checkbox"
                  ref="userIsActive"
                  id="userIsActive"
                  class="w-5 ml-4 h-5 mr-1 cursor-pointer"
                  checked
                /><label
                  for="userIsActive"
                  class="cursor-pointer mb-4 leading-none"
                  >Active</label
                >
              </div>
              <textarea
                v-if="!selectedUser?.isLabSuper"
                :disabled="!editUser"
                name=""
                ref="adminNotes"
                placeholder="Admin Notes"
                id=""
                cols="30"
                rows="5"
                v-model="selectedUserNotes"
                class="border-2 px-2 py-1"
              ></textarea>
            </div>
          </div>
          <div
            class="p-2 mt-4"
            v-if="
              !selectedUser?.isLabSuper && selectedUser?.role != 'lab-admin'
            "
          >
            <div class="flex">
              <div
                @click="activeAccessPanel = 'global'"
                class="text-white font-semibold px-2 pt-2 rounded-t-lg leading-none cursor-pointer"
                :class="[
                  activeAccessPanel == 'global' ? 'bg-gray-700' : 'bg-gray-500',
                ]"
              >
                Global Product Access
              </div>
              <div
                @click="activeAccessPanel = 'custom'"
                class="text-white font-semibold px-2 pt-2 pb-1 rounded-t-lg leading-none cursor-pointer"
                :class="[
                  activeAccessPanel == 'custom' ? 'bg-gray-700' : 'bg-gray-500',
                ]"
              >
                Custom Product Access
              </div>
            </div>
            <div class="relative bg-gray-700 px-2 pb-2 pt-2">
              <div class="relative">
                <div
                  v-if="!editUser || selectedUser.role == 'lab-admin'"
                  class="absolute bg-gray-700 opacity-50 w-full h-full px-0 py-0 z-10"
                ></div>

                <div
                  v-if="activeAccessPanel == 'global'"
                  class="grid grid-cols-1 gap-x-2"
                >
                  <access-box
                    @valchange2="notifyNotSaved"
                    v-model:item-access="dewarAccess"
                    :selected="selectedUser?.globalDewarAccess"
                    id="perm1"
                    >All Lab Access</access-box
                  >
                  <!-- <access-box
                    :disabled="dewarAccess != 'noaccess'"
                    @valchange2="notifyNotSaved"
                    v-model:item-access="rackAccess"
                    :selected="selectedUser?.globalRackAccess"
                    id="perm2"
                    >All Racks Access</access-box
                  >
                  <access-box
                    :disabled="
                      dewarAccess != 'noaccess' || rackAccess != 'noaccess'
                    "
                    @valchange2="notifyNotSaved"
                    v-model:item-access="puckAccess"
                    :selected="selectedUser?.globalPuckAccess"
                    id="perm3"
                    >All Pucks Access</access-box
                  >
                  <access-box
                    :disabled="
                      dewarAccess != 'noaccess' ||
                        rackAccess != 'noaccess' ||
                        puckAccess != 'noaccess'
                    "
                    @valchange2="notifyNotSaved"
                    v-model:item-access="boxAccess"
                    :selected="selectedUser?.globalBoxAccess"
                    id="perm4"
                    >All Boxes Access
                  </access-box> -->
                </div>
                <div v-else>
                  <div
                    class="grid grid-cols-1 sm:grid-cols-2 gap-x-1 gap-y-2 !text-xs font-semibold"
                  >
                    <div class="bg-white border px-2 py-1">
                      <div>Dewars Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        style="overflow-x: auto; min-width: 160px"
                        v-for="(dewar, index) in selectedUser.customDewarAccess"
                        :key="dewar.dewar?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="dewar.dewar?._id"
                          :data-userid="selectedUser._id"
                          data-itemtype="dewar"
                        >
                          {{
                            dewar.dewar?.customId ??
                            "Dewar" + $store.state.twoDigitsPlease(index + 1)
                          }}
                          <br />
                          {{
                            dewar.dewar?.title ? `(${dewar.dewar?.title})` : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="dewarAccessCustom[index]"
                          :selected="dewar.access"
                          item-type="dewar"
                          @val-change="updateCustomChangeContainer"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>
                    <div class="bg-white border px-2 py-1">
                      <div>Racks Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        v-for="(rack, index) in selectedUser.customRackAccess"
                        :key="rack.rack?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="rack.rack?._id"
                          :data-userid="selectedUser._id"
                          data-itemtype="rack"
                        >
                          {{ rack.rack?.serialNo }}<br />
                          {{
                            rack.rack?.title
                              ? `(${rack.rack?.title})`
                              : rack.rack?.customId
                              ? `(${rack.rack?.customId})`
                              : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="rackAccessCustom[index]"
                          :selected="rack.access"
                          item-type="rack"
                          @val-change="updateCustomChangeContainer"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>

                    <div class="bg-white border px-2 py-1">
                      <div>Pucks Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        v-for="(puck, index) in selectedUser.customPuckAccess"
                        :key="puck.puck?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="puck.puck?._id"
                          :data-userid="selectedUser._id"
                          data-itemtype="puck"
                        >
                          {{
                            puck.puck?._id
                              ? puck.puck?.serialNo
                              : "Puck Deleted"
                          }}<br />
                          {{
                            puck.puck?.title
                              ? `(${puck.puck?.title})`
                              : puck.puck?.customId
                              ? `(${puck.puck?.customId})`
                              : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="puckAccessCustom[index]"
                          :selected="puck.access"
                          item-type="puck"
                          @val-change="updateCustomChangeContainer($event)"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>

                    <div class="bg-white border px-2 py-1">
                      <div>Boxes Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        v-for="(box, index) in selectedUser.customBoxAccess"
                        :key="box.box?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="box.box?._id"
                          :data-userid="selectedUser._id"
                          data-itemtype="box"
                        >
                          {{
                            box.box?._id
                              ? box.box?.puckId.serialNo +
                                "-" +
                                $store.state.twoDigitsPlease(box.box?.wellNo)
                              : "Box Deleted"
                          }}
                          <br />
                          {{
                            box.box?.title
                              ? `(${box.box?.title})`
                              : box.box?.customId
                              ? `(${box.box?.customId})`
                              : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="boxAccessCustom[index]"
                          :selected="box.access"
                          @val-change="updateCustomChangeContainer"
                          item-type="box"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-sky-900 text-white p-2 text-sm font-semibold mt-2 flex justify-between items-center"
      >
        <div>Users Activity</div>

        <div class="flex flex-1 justify-end items-center">
          <input
            class="w-4 h-4 mb-1 mr-1 cursor-pointer"
            type="checkbox"
            name=""
            id="showloginscheckbox"
            checked
            @click="
              !$event.target.checked
                ? (showlogins = false)
                : (showlogins = true)
            "
          />
          <label for="showloginscheckbox" class="cursor-pointer"
            >Show Logins</label
          >
        </div>
      </div>
      <div class="localoveride">
        <div v-for="(log, index) in $store.state.logs" :key="log._id">
          <div
            v-if="!(!showlogins && log.isLogin)"
            class="text-xs font-sans p-2"
            :class="[index % 2 == 0 ? 'bg-gray-200' : 'bg-gray-300']"
          >
            <span v-if="log.isLogin">
              {{ log.user.email }} logged in on {{ cleanDate(log.createdAt) }}.
            </span>
            <span v-else>
              {{ log.user.email }} {{ log.verb }}
              {{
                log.connections[0].itemType == "Apikey"
                  ? "an API key"
                  : log.connections[0].itemType
              }}
              <span class="font-semibold">
                {{
                  itemOutput(
                    log?.connections?.[0],
                    log.verb == "moved" ? true : false
                  ) || log.dataCreated
                }}
              </span>

              on {{ cleanDate(log.createdAt) }}.
              <div>
                <div
                  v-if="
                    (log.dataChanged &&
                      JSON.parse(log.dataChanged)?.length > 0) ||
                    (log.dataCreated && log?.connections?.[0].item)
                  "
                  :class="[index % 2 == 0 ? 'bg-blue-50' : 'bg-blue-50']"
                  class="rounded p-2 inline-block"
                >
                  <div v-if="log.dataCreated && log?.connections?.[0]">
                    <span>&bull;</span>{{ log.dataCreated }}
                  </div>
                  <div
                    v-else
                    :key="dataChange.propName"
                    v-for="(dataChange, index) in JSON.parse(log.dataChanged)"
                    :class="[
                      index == JSON.parse(log.dataChanged).length - 1
                        ? 'mb-0'
                        : 'mb-2',
                    ]"
                  >
                    <span>&bull;</span>
                    <span v-if="dataChange?.propName" class="italic capitalize"
                      >{{ dataChange.propName.replace(/([A-Z])/g, " $1") }}
                    </span>
                    <span v-if="dataChange?.from">
                      from "{{ dataChange.from }}"</span
                    >
                    <span>
                      to
                      <span
                        v-if="
                          (dataChange.to &&
                            dataChange?.propName.slice(-8) != 'gridType') ||
                          !(
                            dataChange.to &&
                            dataChange?.propName.slice(-8) == 'gridType' &&
                            Array.isArray(dataChange.to) &&
                            dataChange.to.every((thing) => !thing)
                          )
                        "
                        >"{{ dataChange.to }}"</span
                      ><span class="italic" v-else>Empty</span></span
                    >
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-2">
        <button
          v-show="$store.state.logs.length >= 20"
          @click="appendMoreLogs"
          class="rounded bg-blue-300 px-1 text-black"
        >
          <span class="text-xs">&#9660;</span> Show More
          <span class="text-xs">&#9660;</span>
        </button>
        <div v-show="$store.state.logs.length == 0" class="font-semibold">
          No user activity
        </div>
      </div>
    </div>
    <display-modal
      v-if="showPremiumMessage"
      @ridfeedback="showPremiumMessage = false"
    >
      <no-premo
        >Upgrade to SubAngstrom Premium to add unlimited users.</no-premo
      >
    </display-modal>
  </div>
</template>
<script>
import HeaderBar from "./manage-products-sections/HeaderBar.vue";
import AccessBox from "./group-admin-page/AccessBox.vue";
import CustomItem from "./group-admin-page/CustomItem.vue";
import NoPremo from "../../modal-inners/no-premo.vue";
export default {
  components: { HeaderBar, AccessBox, CustomItem, NoPremo },
  data() {
    return {
      lastClickedUser: null,
      showlogins: true,
      dewarAccess: null,
      rackAccess: null,
      serverdate: null,
      puckAccess: null,
      boxAccess: null,
      showPremiumMessage: false,
      dewarAccessTemp: [],
      userRoleTemp: [],
      rackAccessTemp: [],
      puckAccessTemp: [],
      boxAccessTemp: [],
      dewarAccessCustom: [],
      rackAccessCustom: [],
      puckAccessCustom: [],
      boxAccessCustom: [],
      logCounter: 1,
      selectedUserId: null,
      editUser: null,
      selectedUserNotes: "",
      selectedUserRole: "",
      activeAccessPanel: "global",
      changedCustomItems: [],
      roleChange: null,
    };
  },
  watch: {
    tempUsers() {
      console.log(this.tempUsers);
      for (const [index, user] of this.tempUsers.entries()) {
        this.dewarAccessTemp[index] = user.globalDewarAccess;
        this.rackAccessTemp[index] = user.globalRackAccess;
        this.puckAccessTemp[index] = user.globalPuckAccess;
        this.boxAccessTemp[index] = user.globalBoxAccess;
      }
    },
  },
  computed: {
    selectedUser() {
      return this.$store.state.users.filter((user) => {
        return user._id == this.selectedUserId;
      })[0];
    },
    tempUsers() {
      return this.$store.state.users.filter((user) => user.role == "temp");
    },
    nonTempUsers() {
      return this.$store.state.users.filter((user) => user.role != "temp");
    },
  },

  methods: {
    changeRoleMethod(ev) {
      console.log(ev.target.value);
      this.roleChange = ev.target.value;
    },
    itemOutput(itemData, moved) {
      let text = "";
      if (itemData?.itemType && itemData?.item) {
        console.log(itemData);
        switch (itemData.itemType) {
          case "Box":
            text = itemData.item.title
              ? `(${itemData.item.title})`
              : itemData.item.customId
              ? `(${itemData.item.customId})`
              : "";
            text += ` ${moved ? "from" : "at"} Puck: 
            "${itemData.item?.puckId.serialNo}"
              Well: ${this.$store.state.twoDigitsPlease(itemData.item.wellNo)}`;

            break;
          case "Puck":
            text = itemData.item?.serialNo;
            text += itemData.item.title
              ? `(${itemData.item.title})`
              : itemData.item.customId
              ? `(${itemData.item.customId})`
              : "";
            break;
          case "Dewar":
            text = itemData.item?.customId;
            text += itemData.item.title ? `(${itemData.item.title})` : "";
            break;
          case "Rack":
            text = itemData.item?.serialNo;
            text += itemData.item.title
              ? `(${itemData.item.title})`
              : itemData.item.customId
              ? `(${itemData.item.customId})`
              : "";

            break;
        }
      }
      return text;
    },

    cleanDate(date, shortmonth) {
      let month = "long";
      if (shortmonth) month = "short";
      var options = {
        year: "numeric",
        month: month,
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      var today = new Date(date);

      return today.toLocaleString("en-US", options); // Saturday, September 17, 2016
    },
    updateCustomChangeContainer(ev) {
      const itemid =
        ev.target.parentElement.parentElement.previousElementSibling.dataset
          .itemid;
      const userid =
        ev.target.parentElement.parentElement.previousElementSibling.dataset
          .userid;
      const itemtype =
        ev.target.parentElement.parentElement.previousElementSibling.dataset
          .itemtype;

      var found = false;
      for (var i = 0; i < this.changedCustomItems.length; i++) {
        if (this.changedCustomItems[i].itemid == itemid) {
          found = true;
          this.changedCustomItems[i].customaccess = ev.target.value;
          break;
        }
      }

      if (!found)
        this.changedCustomItems.push({
          itemid,
          userid,
          itemtype,
          customaccess: ev.target.value,
        });

      console.log(this.changedCustomItems);
      this.notifyNotSaved();
    },
    notifyNotSaved() {
      this.$refs.saveUserChanges.style.outline = "2px red dashed";
    },
    loadUser(userId, containerIndex) {
      this.editUser = false;
      console.log(containerIndex);
      this.selectedUserId = userId;
      if (this.lastClickedUser)
        this.lastClickedUser.style.backgroundColor = "white";

      this.lastClickedUser = this.$refs["userContainer" + containerIndex][0];
      this.lastClickedUser.style.backgroundColor = "rgb(228, 240, 228)";

      this.dewarAccess = this.selectedUser.globalDewarAccess;
      this.rackAccess = this.selectedUser.globalRackAccess;
      this.puckAccess = this.selectedUser.globalPuckAccess;
      this.boxAccess = this.selectedUser.globalBoxAccess;
      this.selectedUserNotes = this.selectedUser.adminNotes;
      this.selectedUserRole = this.selectedUser.role;
      console.log(this.selectedUser);
    },
    saveUser(userId, userLab) {
      const body = {
        adminNotes: this.$refs.adminNotes.value,
        globalDewarAccess: this.dewarAccess,
        globalRackAccess: this.rackAccess,
        globalPuckAccess: this.puckAccess,
        globalBoxAccess: this.boxAccess,
      };

      if (this.roleChange) body.role = this.roleChange;

      if (!this.$refs.userIsActive.checked) {
        body.role = "temp";
        this.selectedUser = null;
        this.$refs.userIsActive.checked = "true";
      }

      this.$store
        .dispatch("updateItem", {
          item: "users",
          labid: userLab,
          objid: userId,
          body,
        })
        .then(() => {
          this.editUser = false;
        });

      this.changedCustomItems.forEach((obj, idx, array) => {
        const { itemid, itemtype, customaccess, userid } = obj;

        //if the index of the item is not equal to last index of the changedcustomitemsarray
        if (idx !== array.length - 1) {
          this.$store.state.manageUsersCustomAccessFnc(
            itemtype,
            itemid,
            customaccess,
            userid
          );
        } else {
          this.$store.state
            .manageUsersCustomAccessFnc(itemtype, itemid, customaccess, userid)

            .then(() => {
              //otherwise reset rolechange state and get users for lab
              console.log("here");
              this.$store.state.getUsersForLab();
              this.roleChange = null;
            })

            .catch((err) => console.log(err));
        }
      });

      this.$refs.saveUserChanges.style.outline = "";
    },

    approveUser(userId, userLab, index) {
      if (!this.$store.getters.validPremium) {
        this.showPremiumMessage = true;
        return;
      }

      // let proceed = true;

      // if (
      //   this.dewarAccessTemp[index] == null ||
      //   this.dewarAccessTemp[index] == "2ndnull"
      // ) {
      //   this.dewarAccessTemp[index] = "2ndnull";
      //   proceed = false;
      // }
      // if (
      //   this.rackAccessTemp[index] == null ||
      //   this.rackAccessTemp[index] == "2ndnull"
      // ) {
      //   this.rackAccessTemp[index] = "2ndnull";
      //   proceed = false;
      // }
      // if (
      //   this.puckAccessTemp[index] == null ||
      //   this.puckAccessTemp[index] == "2ndnull"
      // ) {
      //   this.puckAccessTemp[index] = "2ndnull";
      //   proceed = false;
      // }
      // if (
      //   this.boxAccessTemp[index] == null ||
      //   this.boxAccessTemp[index] == "2ndnull"
      // ) {
      //   this.boxAccessTemp[index] = "2ndnull";
      //   proceed = false;
      // }

      // if (proceed == false) return;

      if (
        this.dewarAccessTemp[index] == null ||
        this.dewarAccessTemp[index] == "2ndnull"
      ) {
        this.dewarAccessTemp[index] = "readwrite";
      }
      if (
        this.rackAccessTemp[index] == null ||
        this.rackAccessTemp[index] == "2ndnull"
      ) {
        this.rackAccessTemp[index] = "readwrite";
      }
      if (
        this.puckAccessTemp[index] == null ||
        this.puckAccessTemp[index] == "2ndnull"
      ) {
        this.puckAccessTemp[index] = "readwrite";
      }
      if (
        this.boxAccessTemp[index] == null ||
        this.boxAccessTemp[index] == "2ndnull"
      ) {
        this.boxAccessTemp[index] = "readwrite";
      }

      const body = {
        globalDewarAccess: this.dewarAccessTemp[index],
        globalRackAccess: this.rackAccessTemp[index],
        globalPuckAccess: this.puckAccessTemp[index],
        globalBoxAccess: this.boxAccessTemp[index],
        role: this.userRoleTemp[index] ?? "lab-admin",
      };

      //need to make approval reactive. run get users for lab after dispatch complete
      //need to do delete
      console.log(userLab);
      this.$store.dispatch("updateItem", {
        body,
        labid: userLab,
        item: "users",
        objid: userId,
      });
    },

    deleteUser(userId) {
      const deleteUser = confirm("Are you sure you want to delete this user?");
      if (!deleteUser) {
        return;
      }
      this.$store.dispatch("deleteItem", {
        item: "users",
        objid: userId,
      });
    },

    getUsersActivityForLab() {
      const labid = this.$store.state.activeUser.labId._id;

      this.$store.dispatch("getAdminStuff", {
        url: "logs",
        labid,
      });
    },
    appendMoreLogs() {
      const labid = this.$store.state.activeUser.labId._id;

      this.$store.dispatch("getAdminStuff", {
        url: "logs",
        labid,
        page: ++this.logCounter,
      });
    },
  },
  mounted() {
    this.getUsersActivityForLab();
  },
};
</script>

<style scoped>
button:active {
  color: white !important;
}
textarea {
  border-color: rgb(118, 155, 186);

  resize: none;
}

.localoveride * {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}
.bggray {
  background-color: rgb(237, 237, 237);
}
</style>
