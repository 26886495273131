<template>
  <display-modal
    v-if="displayEditModal"
    @ridfeedback="displayEditModal = false"
  >
    <edit-dewar
      @ridfeedback="
        (dewarindex) => {
          displayEditModal = false;
          con.log(dewarindex, this.activeDewarIndex);
          if (dewarindex == this.activeDewarIndex) this.activeDewarIndex = null;
        }
      "
      :not-saved-answer="this.notSavedAnswer"
      :dewar="this.selectedEditDewar"
      :active-dewar-lab-index="this.selectedEditDewarLabIndex"
      :index="this.selectedEditDewarIndex"
      :dewarLab="this.dewarLab"
      @show-racks-event="
        () => {
          if (!this.showRacks) {
            this.jumptorack = true;
            this.showRacks = true;

            if (this.activeDewar.style.backgroundColor == '') {
              this.colorizeActiveContainer(this.activeDewar, this.activeDewar);
            }
          }
        }
      "
    ></edit-dewar>
  </display-modal>
  <div
    ref="clicktoexpand"
    v-show="showExpandVueWeirdness"
    class="fixed text-white px-2 border border-white bg-gray-900 bg-opacity-75 thisisinterferringwithmouseleave"
    style="z-index: 1000000; top: 0; left: 0; pointer-events: none"
  >
    Click to Expand
  </div>
  <header-bar :below-search="true"
    ><div class="flex w-full items-center">
      <div class="flex-1">Dewars</div>
      <div class="text-base mr-4">Project Filter:</div>
      <select
        @change="setProjectFilter($event)"
        class="w-auto text-sm py-0 mb-0 mr-4"
        name=""
        id=""
      >
        <option value="none">None</option>
        <option
          v-for="project in $store.state.projects"
          :key="'filter' + project._id"
          :value="project._id"
          :selected="compBoxProject == project._id"
        >
          {{ project.name }}
        </option>
      </select>
    </div></header-bar
  >
  <div class="w-12/12 sm:px-4 mx-auto">
    <div class="w-full sm:w-11/12 flex mx-auto">
      <div class="mt-4 flex-1">
        <div
          v-if="showAddDewar && !processingAddDewar"
          class="gridouter w-11/12 mb-4 mx-auto grid grid-cols-2 grid-rows-2 justify-between gap-x-8"
        >
          <div class="text-green-800 text-sm font-bold">Create a new Dewar</div>
          <div>Dewar Details</div>
          <div
            class="gridinner grid-cols-2 grid-rows-3 grid justify-evenly items-center gap-x-4 gap-y-2 text-sm ml-4"
          >
            <span>Dewar ID</span>
            <input
              type="text"
              @click="removeBadBorder($event)"
              placeholder="Dewar ID (required)"
              ref="dewarId"
            />
            <span>Dewar Title</span>
            <input type="text" placeholder="Dewar Title" ref="dewarTitle" />
            <span>Dewar Capacity</span>
            <div class="flex">
              <input
                type="number"
                placeholder="Dewar Capacity"
                ref="dewarCapacity"
              />
              <div
                @click="createDewar"
                class="rounded-md text-lg cursor-pointer flex items-center border-1 border-green-900 ml-4 px-8 text-white font-bold bg-emerald-600"
              >
                Create
              </div>
            </div>
          </div>

          <textarea
            class="border-2 py-1 px-2"
            placeholder="Dewar Description"
            ref="dewarDescription"
          ></textarea>
        </div>
        <div
          v-show="processingAddDewar"
          class="mb-4 flex justify-center w-full"
        >
          <div
            class="border-2 rounded-md border-blue-800 w-1/2 dewar-container-bg text-center p-2 font-semibold relative"
          >
            <div ref="creatingdewar" class="relative">
              <div>
                Creating Dewar
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  style="margin: auto; display: inline-block"
                  width="3rem"
                  height="3rem"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <g transform="rotate(0 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.9166666666666666s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(30 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.8333333333333334s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(60 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.75s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(90 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.6666666666666666s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(120 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.5833333333333334s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(150 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.5s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(180 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.4166666666666667s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(210 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.3333333333333333s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(240 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.25s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(270 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.16666666666666666s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(300 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.08333333333333333s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                  <g transform="rotate(330 50 50)">
                    <rect
                      x="47"
                      y="24"
                      rx="3"
                      ry="6"
                      width="6"
                      height="12"
                      fill="#1d3f72"
                    >
                      <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="0s"
                        repeatCount="indefinite"
                      ></animate>
                    </rect>
                  </g>
                </svg>
              </div>
              <div
                class="opacity-0 invisible absolute"
                style="
                  transition: opacity 3s ease-in-out;
                  transform: translate(-50%, -50%);
                  top: 50%;
                  left: 50%;
                "
              >
                Created!
              </div>
              <div
                class="opacity-0 hidden"
                style="transition: opacity 3s ease-in-out"
              >
                Error creating Dewar. Please contact the SubAngstrom support
                team or try refreshing this page and creating it again.
              </div>
            </div>
          </div>
        </div>
        <div
          id="dewar-container-parent"
          class="custom-gray-color rounded-xl border-2 border-gray-600 overflow-hidden"
        >
          <div
            v-for="(lab, labindex) in arrayOfDewars"
            :key="lab?.lab"
            class="w-full"
          >
            <div class="flex justify-between">
              <div
                class="rounded-br-xl bg-sky-900 inline-block text-white px-4 py-1 lg:py-2 lg:text-xl font-bold"
              >
                {{ lab?.lab }}
              </div>
              <div
                v-if="
                  !showAddDewar && $store.state.activeUser.role == 'lab-admin'
                "
                @click="showAddDewar = true"
                class="text-white font-xl font-semibold bg-emerald-600 mt-2 mr-2 px-4 flex items-center rounded cursor-pointer"
              >
                <span class="material-symbols-outlined text-white text-xl mr-2">
                  add_circle
                </span>
                <!-- <i class="fas fa-plus-circle text-white text-xl mr-2"></i> Add -->
                Dewar
              </div>
              <div
                @click="showAddDewar = false"
                class="text-white font-xl font-semibold bg-gray-600 mt-2 mr-2 px-4 flex items-center rounded cursor-pointer"
                v-if="
                  showAddDewar && $store.state.activeUser.role == 'lab-admin'
                "
              >
                Cancel Add Dewar
              </div>
            </div>

            <div class="dewar-container items-end grid gridfit gap-x-2 p-2">
              <div
                class="mt-2"
                :key="dewar._id"
                v-for="(dewar, index) in lab?.data"
              >
                <div class="w-full flex justify-end" v-if="!dewar.readOnly">
                  <div
                    @click="
                      (ev) => {
                        selectedEditDewarIndex = index;
                        selectedEditDewarLabIndex = labindex;
                        displayEditModal = true;

                        if (!this.activeDewar) {
                          con.log(ev.target);
                          const parentContainer =
                            ev.currentTarget.parentElement.nextSibling;
                          this.activeDewar = parentContainer;
                        }
                      }
                    "
                    class="bg-sky-900 rounded-t-md py-1 cursor-pointer rounded-b-none text-white semi-bold px-3"
                  >
                    <!-- <i class="fa-regular fa-pen-to-square"></i> -->
                    <span
                      class="material-symbols-outlined text-sm font-semibold"
                    >
                      edit_square
                    </span>
                    <span class="text-sm font-semibold">&nbsp;Edit Dewar</span>
                  </div>
                </div>
                <div
                  @mousemove="showRackExpandMessage($event, false)"
                  @mouseleave="showRackExpandMessage($event, true)"
                  :ref="dewar._id"
                  @click="
                    showEditDewarsAndMoveTo(
                      $event,
                      dewar.racks.length,
                      labindex,
                      index
                    )
                  "
                  :id="'papaBgChange1-' + index"
                  class="custom-blue-border cursor-pointer w-full bg-gray-100 papaBgChange rounded-xl rounded-tr-none border-2 mt-0 px-2 pt-1 pb-0 inline-block"
                >
                  <div class="flex">
                    <div class="flex-1">
                      <img
                        class="w-full max-w-[120px]"
                        src="assets/images/dewar.svg"
                      />
                    </div>
                    <div
                      class="flex-1 ml-4 text-center font-bold text-xs text-gray-800"
                    >
                      <div
                        class="leading-tight text-5xl font-bold text-red-600"
                      >
                        {{ dewar.racks.length }}
                      </div>
                      <div
                        class="border-b-2 pb-2 border-dashed border-gray-800"
                      >
                        Racks Inside
                      </div>

                      <div class="">
                        Capacity
                        <span class="text-3xl">{{ dewar.capacity }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-lg font-bold text-green-800">
                    {{
                      !dewar.customId ? "Dewar " + (index + 1) : dewar.customId
                    }}
                  </div>
                  <div class="text-lg font-bold text-green-800">
                    {{ dewar.labId.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="arrayOfDewars[0]?.data.length > 0"
      class="flex w-11/12 mx-auto mt-4 items-stretch"
    >
      <Checkbox
        inputid="edit-dewars-checkbox"
        :is-checked="showDewarEdit"
        @change="showDewarEditFn"
      />
      <label
        for="edit-dewars-checkbox"
        class="cursor-pointer text-base leading-tight"
        >&nbsp;Edit/Remove Dewars</label
      >
    </div> -->
    <div
      v-if="!showAddDewar && arrayOfDewars[0]?.data.length == 0"
      class="flex w-11/12 mx-auto mt-4 items-stretch"
    >
      No dewars found.
    </div>
    <!--- GRID DETAILED -->
    <!--- GRID DETAILED START -->
    <!--- GRID DETAILED -->
  </div>
  <!--- START OF RACKS -->
  <!--- START OF RACKS -->
  <!--- START OF RACKS -->
  <div v-if="showRacks">
    <header-bar>Racks</header-bar>
    <rack-section
      :resetRackFromDewar="resetRackFromDewar"
      :jump="jumptorack"
      :autoclickrack="autoclickrack"
      :active-dewar-lab-index="activeDewarLabIndex"
      :active-dewar-id="activeDewarId"
      :colorizeActiveContainer="colorizeActiveContainer"
      @hook:mounted="mountsafeautoclick = true"
      @setrackfalse="autoclickrack = false"
      @resettotrue="resetRackFromDewar = false"
    ></rack-section>
  </div>
  <display-modal v-if="showPremo" @ridfeedback="showPremo = false">
    <no-premo>{{ showPremoMsg }}</no-premo>
  </display-modal>
</template>

<script>
import HeaderBar from "./HeaderBar.vue";
import RackSection from "./RackSection.vue";
import EditDewar from "./EditDewar.vue";

import NoPremo from "../../../modal-inners/no-premo.vue";

export default {
  components: { HeaderBar, RackSection, NoPremo, EditDewar },
  created() {
    window.addEventListener("beforeunload", (e) => {
      if (
        this.$store.state.unsavedChangesMPGrid ||
        this.$store.state.unsavedChangesMPBox
      ) {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    });
  },
  mounted() {
    this.$emit("dewarsmounted");
    if (this.$store.state.activeUser.role == "lab-admin") this.getUsersForLab();

    if (this.$store.state.activeUser.role != "lab-admin") {
      console.log("notadmins");
      const url =
        this.$store.state.hostname + "/api/v1/projects/getnonadminprojects";

      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            console.log(data.data);
            this.$store.state.projects = data.data.data
              .filter((linkobj) => linkobj.projectId)
              .map((project) => project.projectId);
          }
        });
    } else {
      this.$store.dispatch("getAdminStuff", {
        url: "projects",
      });
    }
  },
  inject: ["removeBadBorder", "resetUnsavedData"],
  provide() {
    //activedewarindex is null in the dewar.vue but when u click a rack it gets set

    // use function syntax so that we can access `this`
    return {
      showRackExpandMessage: this.showRackExpandMessage,
      saveData: this.saveData,

      activeDewarLabIndex: this.activeDewarLabIndex,

      showTimeoutMessage: this.showTimeoutMessage,
      showExpandVueWeirdness: this.showExpandVueWeirdness,
    };
  },
  props: ["autoclick", "firstdewardata"],

  data() {
    return {
      activeDewar: null,
      displayEditModal: false,
      jumptorack: true,
      showExpandVueWeirdness: false,
      showTimeoutMessage: null,
      activeDewar2: null,
      resetRackFromDewar: false,
      showAddDewar: false,
      processingAddDewar: false,
      activeDewarLabIndex: 0,
      activeDewarIndex: null,
      showDewarEdit: false,
      saveTimeouts: {},
      saveTimeout: null,
      saveTimestamp: null,
      showPremo: false,
      showPremoMsg: "",
      autoclickrack: false,
      startdragindex: null,
      lastmovehere: null,

      showRacks: false,

      mountsafeautoclick: false,

      selectedEditDewarIndex: null,
      selectedEditDewarLabIndex: null,
    };
  },

  computed: {
    selectedEditDewar() {
      return this.$store.state.dewarData[this.activeDewarLabIndex].data[
        this.selectedEditDewarIndex
      ];
    },
    compBoxProject() {
      return this.getCookie("lastprojectfilter");
    },
    forWatcher() {
      return this.$store.state.dewarData;
    },

    dewarLab() {
      console.log(
        "urdewar",
        this.activeDewarId,
        this.activeDewarLabIndex,

        this.$store.state.dewarData
      );

      //so the lab is
      if (this.activeDewarLabIndex)
        return this.$store.state.dewarData[this.activeDewarLabIndex].labId;
      else return this.$store.state.dewarData[0].labId;
    },

    arrayOfDewars() {
      return this.$store.state.dewarData;
    },
    activeDewarId() {
      //gets the id from the id html attribute name
      if (this.activeDewar) return this.activeDewar.id.split("-")[1];
      else return null;
    },
  },
  watch: {
    firstdewardata(val) {
      if (val) {
        if (this.compBoxProject && this.compBoxProject != "none")
          this.setProjectFilter(this.compBoxProject, true);
      }
    },
    autoclick() {
      if (this.autoclick) {
        console.log(this.autoclick, this.$refs[this.autoclick.dewar][0]);
        this.$refs[this.autoclick.dewar][0].click();
        console.log("autoclickrack set from dewar");
        this.autoclickrack = this.autoclick;
        console.log(this.autoclickrack);
      }
    },
    forWatcher: function () {
      if (this.processingAddDewar) {
        setTimeout(() => {
          this.processingAddDewar = false;
          this.$refs.creatingdewar.children[0].style.visibility = "visible";
          this.$refs.creatingdewar.children[1].style.opacity = "0";
          this.$refs.creatingdewar.children[1].style.visiblity = "hidden";
        }, 1500);
      }
    },
    showDewarEdit: function () {
      console.log("changed");
      if (this.activeDewar && this.showDewarEdit == false) {
        this.activeDewar.style.backgroundColor = "";
        this.activeDewar = null;
        this.activeDewarIndex = null;
        this.showRacks = false;
      }
      if (this.activeDewar2 && this.showDewarEdit == false) {
        this.activeDewar2.style.backgroundColor = "";
        this.activeDewar2 = null;
      }
    },
  },
  methods: {
    async saveData(
      ev,
      id,
      item,
      property,
      onblurexec,
      forpayload,
      datepickerworkaround
    ) {
      console.log("ENTERED SAVEDATA");
      let externalResolveForSerialNumber;
      let bodysaverforimageurlpromise;
      const promisetoreturn = new Promise((resolve) => {
        externalResolveForSerialNumber = resolve; // Assign the inner resolve to externalResolve.
      });

      let indicatorElement = null;
      if (!onblurexec) {
        // Skip indicator for blur events
        // Create the indicator element
        indicatorElement = document.createElement("div");
        indicatorElement.style.position = "fixed";
        indicatorElement.style.zIndex = "100000";
        indicatorElement.style.width = "32px";
        indicatorElement.style.height = "32px";
        indicatorElement.style.transition = "opacity 0.5s ease-out";
        indicatorElement.style.opacity = "0";
        indicatorElement.style.backgroundColor = "white";
        indicatorElement.style.borderRadius = "50%";
        indicatorElement.style.boxShadow = "0 2px 5px rgba(0,0,0,0.3)";
        indicatorElement.style.display = "flex";
        indicatorElement.style.alignItems = "center";
        indicatorElement.style.justifyContent = "center";

        // Position the indicator near the element that triggered the save
        if (ev && ev.currentTarget) {
          const rect = ev.currentTarget.getBoundingClientRect();
          indicatorElement.style.left = `${rect.left - 40}px`;
          indicatorElement.style.top = `${rect.top + rect.height / 2 - 16}px`;
        } else {
          indicatorElement.style.left = "50%";
          indicatorElement.style.top = "50%";
          indicatorElement.style.transform = "translate(-50%, -50%)";
        }

        // Add the indicator to the document body
        document.body.appendChild(indicatorElement);
      }

      if (item == "boxes" && !id) {
        if (document.getElementById("saveCreateBoxChanges"))
          document.getElementById("saveCreateBoxChanges").style.outline =
            "2px red dashed";
        this.$store.state.unsavedChangesMPBox = true;
        return;
      }

      if (onblurexec) console.log("blueran");
      else console.log("noblue");

      clearTimeout(this.saveTimeout);

      if (property == "gridProperty") {
        this.$store.state.disableBoxUpdate = true;
        clearTimeout(this.$store.state.gridSaveTimeout);
        console.log("cleared timeout", this.$store.state.gridSaveTimeout);
      }

      let input = ev?.currentTarget?.value;
      if (!ev) input = datepickerworkaround;

      console.log("forpayload1", forpayload, input);
      const localfunc = async () => {
        this.saveTimestamp = Date.now();
        console.log("in localf unc");

        let body = {
          [property]: input,
        };

        console.log(forpayload);
        if (forpayload.rackid) body.rackid = forpayload.rackid;

        if (forpayload.puckId) body.puckId = forpayload.puckId;
        if (forpayload.wellNo) body.wellNo = forpayload.wellNo;

        if (!forpayload.dewarIndex && forpayload.dewarIndex !== 0)
          forpayload.dewarIndex = this.activeDewarIndex;
        if (!forpayload.labIndex && forpayload.labIndex !== 0)
          forpayload.labIndex = this.activeDewarLabIndex;

        forpayload.propertytoupdate = property;

        console.log(property, forpayload);
        if (property == "gridProperty") {
          body = forpayload.gridProperty;

          console.log(forpayload);
          if (
            input ==
            this.$store.state.dewarData[forpayload.labIndex].data[
              forpayload.dewarIndex
            ].racks[forpayload.gridProperty.rackIndex].pucks[
              forpayload.gridProperty.puckIndex
            ]["well" + forpayload.gridProperty.wellNo][
              forpayload.gridProperty.gridupdate[0]
            ][forpayload.gridProperty.gridupdate[1]]
          ) {
            this.$store.state.disableBoxUpdate = false;
            return;
          } else {
            console.log("updating");
          }
        }

        console.log("YOO1", {
          item,
          objid: id,
          body: body,
          forpayload,
        });
        bodysaverforimageurlpromise = body.gridupdate?.[1];
        try {
          await this.$store.dispatch("updateItem", {
            item,
            objid: id,
            body: body,
            forpayload,
          });

          if (indicatorElement) {
            indicatorElement.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="#22c55e" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
      </svg>
    `;
            indicatorElement.style.opacity = "1";

            // Animate and remove after delay
            setTimeout(() => {
              indicatorElement.style.opacity = "0";
              setTimeout(() => {
                indicatorElement.remove();
              }, 500);
            }, 1500);
          }

          if (
            property == "serialNo" ||
            bodysaverforimageurlpromise == "imageurl"
          ) {
            console.log("why u run");
            externalResolveForSerialNumber(id);
          }
        } catch (err) {
          console.log("something breaking", err);

          // Show error indicator (red X)
          if (indicatorElement) {
            // Change background color to red for error state
            indicatorElement.style.backgroundColor = "#ef4444";

            indicatorElement.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round">
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="15" y1="9" x2="9" y2="15"></line>
        <line x1="9" y1="9" x2="15" y2="15"></line>
      </svg>
    `;
            indicatorElement.style.opacity = "1";

            // Animate and remove after delay
            setTimeout(() => {
              indicatorElement.style.opacity = "0";
              setTimeout(() => {
                indicatorElement.remove();
              }, 500);
            }, 1500);
          }

          let text;
          switch (item) {
            case "dewars":
              text = "Dewar";
              break;
            case "racks":
              text = "Rack";
              break;
            case "pucks":
              text = "Puck";
              break;
          }

          if (item == "boxes") {
            if (property == "gridProperty") text = "Grid";
            else text = "Box";
          }

          document.getElementById("savefailid").insertAdjacentHTML(
            "afterbegin",
            `<div
       class="shadow-md cursor-pointer rounded bg-yellow-50" style="transition: all 0.5s ease-in-out; line-height:0; overflow: hidden; padding: 0; border-width:0;  border-color: rgb(248 113 113);">
            ${text} save failed! Please check internet connection and try
            again.
          </div>`
          );
          document
            .getElementById("savefailid")
            .firstElementChild.addEventListener("click", (ev) => {
              ev.currentTarget.remove();
            });
          setTimeout(() => {
            document.getElementById("savefailid").firstElementChild.style =
              "margin-bottom:0.5rem; transition: all 0.5s ease-in-out; line-height: 1.5; padding: 0.5rem 1rem; border-width:2px;  border-color: rgb(248 113 113);";
          }, 500);
        }
      };

      if (onblurexec) {
        localfunc();
      } else {
        this.saveTimeout = setTimeout(() => {
          if (Date.now() > this.saveTimestamp + 1000) {
            localfunc();
          }
        }, 1000);
      }

      console.log("bodysaverforimageurlpromise", bodysaverforimageurlpromise);
      if (property == "serialNo" || bodysaverforimageurlpromise == "imageurl") {
        console.log("returning a promise");
        return promisetoreturn;
      }
    },
    showRackExpandMessage(ev, hide) {
      if (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      )
        return;

      clearTimeout(this.showTimeoutMessage);
      // console.log(
      //   "mouse locationz:",
      //   ev.clientX,
      //   ev.clientY,
      //   ev.target.tagName.toLowerCase(),
      //   ev.target
      // );

      //run this code so long as it's not an input element
      if (
        ev.target.tagName.toLowerCase() != "input" &&
        ev.target.tagName.toLowerCase() != "textarea" &&
        ev.target.tagName.toLowerCase() != "label" &&
        ev.target.tagName.toLowerCase() != "button" &&
        !ev.target.closest(".puckslist") &&
        !ev.target.classList.contains("puckslist")
      ) {
        // if statement hide is true if mouseleave's form

        if (hide) {
          console.log(ev);
          //had nothing to do with vue. was related tot the element triggering mosueleave
          if (
            !ev.toElement?.classList.contains(
              "thisisinterferringwithmouseleave"
            )
          )
            this.showExpandVueWeirdness = false;
          // this.showTimeoutMessage = null;
        } else {
          // this.$refs.clicktoexpand.style.display = "none";
          // if (this.showTimeoutMessage) {
          //   console.log("timeout exists");
          // } else {
          //   console.log("timeout no exists");

          // this.showTimeoutMessage = null;

          this.showTimeoutMessage = setTimeout(() => {
            // console.log(
            //   ev.clientX,
            //   ev.screenX,
            //   ev.clientY,
            //   ev.screenY,
            //   document.querySelector(".nav").style.width
            // );

            //this should be the last mouse position before the stop

            this.$refs.clicktoexpand.style.left = ev.clientX + 14 + "px";
            this.$refs.clicktoexpand.style.top = ev.clientY + "px";
            this.showExpandVueWeirdness = true;

            console.log(
              "the timeout",
              ev.clientX,
              ev.clientY,
              this.showTimeoutMessage,
              this.$refs.clicktoexpand,
              this.showExpandVueWeirdness
            );
          }, 250);
        }
      } else {
        this.showExpandVueWeirdness = false;

        // this.showTimeoutMessage = null;
      }
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    getUsersForLab() {
      //when a user selects a lab from update lab

      const labid = this.$store.state.activeUser.labId._id;

      this.$store.dispatch("getAdminStuff", {
        url: "users",
        labid,
      });
    },

    async showEditDewarsAndMoveTo(ev, hasRacks, labindex, dewarindex) {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) return;
        else {
          this.resetUnsavedData();
        }
      }

      //to prevent action from taking place if dewar is already highlighted
      if (
        labindex == this.activeDewarLabIndex &&
        dewarindex === this.activeDewarIndex
      ) {
        console.log(dewarindex, this.activeDewarIndex);
        return;
      }

      //notify racksection the dewar has changed.
      if (this.showRacks) this.resetRackFromDewar = true;

      console.log("dewar clicked");

      const parentContainer = ev.target.closest(".papaBgChange");
      //lastDewarDivClicked

      const parentContainerRe = this.colorizeActiveContainer(
        parentContainer,
        this.activeDewar
      );

      this.activeDewarIndex = dewarindex;
      this.activeDewarLabIndex = labindex;
      this.activeDewar = parentContainerRe;

      ev.target.checked = true;

      if (!hasRacks) {
        this.jumptorack = false;
        this.showRacks = false;
        // this.showDewarEditFn(ev);
        this.selectedEditDewarIndex = this.activeDewarIndex;
        this.selectedEditDewarLabIndex = labindex;
        this.displayEditModal = true;
      } else {
        this.jumptorack = true;
        //if edit is clicked show the dewar edit stuff
        // if (this.showDewarEdit) this.showDewarEditFn(ev);
        this.showRacks = true;
      }
      this.highlightEditDewar(this.jumptorack);
    },
    async showDewarEditFn(ev) {
      if (!ev.target.checked) {
        if (
          this.$store.state.unsavedChangesMPBox ||
          this.$store.state.unsavedChangesMPGrid
        ) {
          this.$store.state.promptUnsaveModal = true;

          let answer;
          try {
            answer = await this.$store.state.promptAnswerPromise();
          } catch {
            //rejected

            answer = null;
          }

          this.$store.state.promptUnsaveModal = false;
          if (!answer) {
            ev.target.checked = true;
            return;
          } else {
            this.resetUnsavedData();
          }
        }
      }

      if (ev.target.checked) this.showDewarEdit = true;
      else this.showDewarEdit = false;

      if (!this.activeDewar2 && !this.activeDewar) this.highlightEditDewar();
    },

    highlightEditDewar(jumptorack) {
      //this stuff was probabyl for the old edit dewar but im leaving it in
      if (this.activeDewar) {
        console.log("papaBgChange1-" + this.activeDewar.id.split("-")[1]);
        const parentContainer =
          this.$refs["papaBgChange1-" + this.activeDewar.id.split("-")[1]];

        //scroll to the edit dewar for the related clicked dewar
        // if (!jumptorack)
        //   setTimeout(() => {
        //     window.scrollIntoView(parentContainer);
        //   }, 500);

        if (
          this.activeDewar2 &&
          this.activeDewar2 == parentContainer &&
          this.activeDewar2.style.backgroundColor != ""
        )
          this.activeDewar2.style.backgroundColor = "";
        else if (
          this.activeDewar2 &&
          this.activeDewar2 == parentContainer &&
          this.activeDewar2.style.backgroundColor == ""
        )
          this.activeDewar2.style.backgroundColor = "rgb(191, 216, 235)";
        else if (this.activeDewar2) {
          parentContainer.style.backgroundColor = "rgb(191, 216, 235)";
          this.activeDewar2.style.backgroundColor = "";
        } else {
          // parentContainer.style.backgroundColor = "rgb(191, 216, 235)";
        }
        this.activeDewar2 = parentContainer;
      }
    },
    async setProjectFilter(event, frommount) {
      if (!frommount) {
        const answer = await this.notSavedAnswer();
        console.log(answer, event.target);
        if (!answer) {
          event.target.selectedIndex = 0;

          return false;
        } else {
          this.resetUnsavedData();
        }

        //reset state of active dewar
        if (this.activeDewar) {
          this.activeDewar.style.backgroundColor = "";
          if (this.activeDewar2) this.activeDewar2.style.backgroundColor = "";
        }
        this.showRacks = false;
        this.activeDewarIndex = null;

        await this.$store.dispatch("updateDewarData");
        this.setCookie("lastprojectfilter", event.target.value, 365);
        if (event.target.value == "none") return;
      }
      console.log(this.$store.state.dewarData[0].data);

      const idcomparison = frommount ? event : event.target.value;
      console.log(idcomparison);

      for (let i = 0; i < this.$store.state.dewarData[0].data.length; i++) {
        let atleast1rackmatch = false;
        for (
          let irack = 0;
          irack < this.$store.state.dewarData[0].data[i].racks.length;
          irack++
        ) {
          let currentrackmatch = false;
          // console.log(
          //   "RACK THING:",
          //   this.$store.state.dewarData[0].data[i].racks[irack]?.project,
          //   idcomparison
          // );
          if (
            this.$store.state.dewarData[0].data[i].racks[irack]?.project ===
            idcomparison
          ) {
            atleast1rackmatch = true;
            currentrackmatch = true;
            console.log("rack match made");
          }
          //console.log(this.$store.state.dewarData[0].data[i].racks[irack]);

          let atleast1puckmatch = false;
          for (
            let ipuck = 0;
            ipuck <
            this.$store.state.dewarData[0].data[i].racks[irack].pucks.length;
            ipuck++
          ) {
            let currentpuckmatch = false;
            if (
              this.$store.state.dewarData[0].data[i].racks[irack].pucks[ipuck]
                ?.project == idcomparison
            ) {
              atleast1puckmatch = true;
              currentpuckmatch = true;
            }

            let atleast1wellmatch = false;
            for (let iwell = 0; iwell < 12; iwell++) {
              if (
                this.$store.state.dewarData[0].data[i].racks[irack].pucks[
                  ipuck
                ]["well" + iwell]?.project == idcomparison
              ) {
                atleast1wellmatch = true;
                console.log("wellmatch");
                //console.log(event.target.value);
              }
              if (iwell == 11) {
                if (
                  !atleast1wellmatch &&
                  !currentpuckmatch &&
                  !currentrackmatch
                ) {
                  this.$store.state.dewarData[0].data[i].racks[
                    irack
                  ].pucks.splice(ipuck, 1);
                  ipuck--;
                } else {
                  atleast1puckmatch = true;
                  atleast1rackmatch = true;
                }

                atleast1wellmatch = false;
              }
            }
          }
          console.log(atleast1puckmatch, atleast1rackmatch);
          if (!atleast1puckmatch && !currentrackmatch) {
            this.$store.state.dewarData[0].data[i].racks.splice(irack, 1);
            console.log(irack);
            irack--;
          }
        }
        if (!atleast1rackmatch) {
          this.$store.state.dewarData[0].data.splice(i, 1);

          i--;
        }
      }

      //loop through all boxes with the project property that equals this id.
      //set dewardata to that.
      //event.target.value;
    },
    async notSavedAnswer() {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        return answer;
      } else {
        return true;
      }
    },

    colorizeActiveContainer(parentContainer, trackingVar) {
      //if trackingvar null first time dewar is active
      console.log(trackingVar == parentContainer);
      if (
        trackingVar &&
        trackingVar == parentContainer &&
        trackingVar.style.backgroundColor == ""
      )
        trackingVar.style.backgroundColor = "rgb(191, 216, 235)";
      else if (trackingVar) {
        parentContainer.style.backgroundColor = "rgb(191, 216, 235)";
        trackingVar.style.backgroundColor = "";
      } else {
        parentContainer.style.backgroundColor = "rgb(191, 216, 235)";
      }

      return parentContainer;
    },
    showPremiumMessage(isCapacity) {
      if (isCapacity)
        this.showPremoMsg =
          "Upgrade to SubAngstrom Premium for unlimited dewar capacity.";
      else
        this.showPremoMsg =
          "Upgrade to SubAngstrom Premium to add unlimited dewars.";
      this.showPremo = true;
    },
    async createDewar() {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          return;
        } else {
          this.resetUnsavedData();
        }
      }

      if (!this.$store.getters.validPremium) {
        if (this.arrayOfDewars[0]?.data.length > 1) {
          this.showPremiumMessage();
          return;
        }
      }

      if (!this.$refs.dewarId.value) {
        this.$refs.dewarId.classList.add("bad-border");
        return;
      }
      const payloadBody = {
        title: this.$refs.dewarTitle.value,
        details: this.$refs.dewarDescription.value,
        capacity: this.$refs.dewarCapacity.value * 1,
        customId: this.$refs.dewarId.value,
      };

      this.showAddDewar = false;
      this.processingAddDewar = true;
      this.$store
        .dispatch("createItem", { body: payloadBody, item: "dewars" })
        .then(() => {
          this.$refs.creatingdewar.children[0].style.visibility = "hidden";

          this.$refs.creatingdewar.children[1].style.visibility = "visible";

          this.$refs.creatingdewar.children[1].style.opacity = "1";
        })
        .catch((err) => {
          console.log(err);
          this.$refs.creatingdewar.children[0].style.visbility = "hidden";
          this.$refs.creatingdewar.children[2].style.visbility = "visible";

          this.$refs.creatingdewar.children[2].style.opacity = "1";
        });
    },
  },
};
</script>

<style scoped>
.custom-blue-border {
  border-color: rgb(118, 155, 186);
}
.custom-blue-bg {
  background-color: rgb(118, 155, 186);
}
input {
  padding: 0;
  margin-bottom: 0.25rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="radio"] {
  margin: 0;
}
.edit-dewar {
  bottom: -0.5rem;

  position: relative;
}

.maxwfitcontent {
  max-width: fit-content;
}

.dewar-container-bg {
  background: rgb(208, 232, 214);
}

/* .dewar-container > div {
  width: calc(25% - 1rem);
} */

.textarea-dewaredit {
  min-height: 100px;
}

@media (max-width: 1300px) {
  .dewar-container > div {
    width: 100%;
  }
}

.gridinner {
  grid-template-columns: max-content max-content;
}

.gridfit {
  /* width: calc(100% - 1rem);
  grid-template-columns: repeat(4, 1fr); */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
@media (min-width: 640px) {
  .gridfit {
    /* width: calc(100% - 1rem);
  grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  }
}

.gridouter {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
}

.gridinner input {
  margin: 0;
  padding: 0.25rem 0.5rem;
}

.dewarImg {
  width: 80%;
}

.inputmatchselect {
  padding: 0.5rem 0.5rem;
  width: 50%;
}
</style>
